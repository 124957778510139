<template>
  <div class="clouducation">
    <TheHeader @register="toggleRegistration()" @login="toggleLoginModal()" @scroll="scrollTo" @sidebar="toggleSidebar()" />
    <div class="body">
      <div class="hero relative">
        <div class="flip absolute -left-24 top-0" v-if="!isMobile">
          <Hexagon color="yellow" />
        </div>
        <div class="container pt-10 pb-16 relative z-1 mdmax:px-4" id="hero">
          <div class="mb-8 text-center">
            <div class="mdmax:px-10">
              <img src="@/assets/images/microsite/cloud-figure.png" class="inline-block" />
            </div>
            <p class="cloud-title text-white font-bold mdmax:text-4xl"><span class="text-yellow">CLOUD</span>UCATION</p>
            <p class="font-bold text-3xl text-white mdmax:text-sm"><span class="text-yellow">SIMPLIFY TECHNOLOGY</span> FOR YOUR LIFE</p>
          </div>
          <div class="text-center">
            <Button
              buttonText="Live Streaming"
              type="primary"
              @action="goToUrl(programDetail.location)"
              v-if="isUserEventLoggedIn"
              :disabled="!isEventStarted(programDetail.schedule, '12.00', programDetail.scheduleTo)"
            />
            <Button buttonText="Register" type="primary" @action="toggleRegistration()" v-else />
            <p class="text-sm text-center mt-5 text-white" v-if="isUserEventLoggedIn && !isEventEnded(programDetail.schedule, programDetail.scheduleTo)">
              Live streaming will be available on 19 November, 12.00 WIB
            </p>
          </div>
          <div class="countdown w-2/5 mx-auto px-9 mdmax:px-10 mdmax:w-full">
            <Countdown :date="formatDisplayDate(programDetail.schedule, programDetail.scheduleFrom)" />
          </div>
        </div>
      </div>
      <div class="bg-clouducation-black relative overflow-hidden">
        <div class="absolute -right-48 bottom-0 z-0" v-if="!isMobile">
          <Hexagon color="clouducation-purple" opacity="1" />
        </div>
        <div class="container about py-16 flex gap-12 text-white mdmax:flex-col mdmax:gap-9 mdmax:py-8 mdmax:px-4" id="about-section">
          <div class="flex-1 mdmax:order-2">
            <p class="text-base mb-16 mdmax:text-sm mdmax:mb-10" v-html="programDetail.description"></p>
            <div class="flex gap-16 mdmax:flex-col mdmax:gap-0">
              <div class="flex gap-5 mb-4 flex-col mdmax:gap-4">
                <span class="flex gap-2 items-center hover:text-yellow w-48">
                  <CalendarNumber width="24" height="24" color="clouducation-purple" />
                  <span class="inline-block text-white mdmax:text-xs">{{ dayjs(programDetail.schedule).format('DD MMMM YYYY') }}</span>
                </span>
                <span class="flex gap-2 items-center hover:text-yellow w-48">
                  <Time width="24" height="24" color="clouducation-purple" />
                  <span class="inline-block text-white mdmax:text-xs">{{ programDetail.scheduleFrom }} - {{ programDetail.scheduleTo }} WIB</span>
                </span>
              </div>
              <div class="flex gap-5 flex-col mdmax:gap-4">
                <a href="https://www.instagram.com/g2academyindonesia/" target="_blank" class="flex gap-2 items-center hover:text-yellow">
                  <span class="w-6 h-6 rounded-full bg-clouducation-purple flex justify-center items-center">
                    <Instagram width="14" height="14" color="clouducation-black" />
                  </span>
                  <span class="inline-block hover:text-yellow mdmax:text-xs">g2academyindonesia</span>
                </a>
                <a href="https://www.facebook.com/g2academygeeks" target="_blank" class="flex gap-2 items-center hover:text-yellow">
                  <span class="w-6 h-6 rounded-full bg-clouducation-purple flex justify-center items-center">
                    <Facebook width="20" height="14" color="clouducation-black" />
                  </span>
                  <span class="inline-block hover:text-yellow mdmax:text-xs">G2Academy</span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1 mdmax:order-1">
            <div class="video mb-6">
              <div class="video-wrapper">
                <iframe :src="programDetail.syllabus" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div class="flex justify-center items-center gap-16 mdmax:gap-6">
              <div class="hex flex justify-center flex-col items-center mdmax:transform scale-50" v-for="(price, index) in priceGroup" :key="'price-' + index">
                <p class="text-center font-semibold text-white opacity-50 line-through mdmax:text-base" v-if="price.split(',')[2]">{{ price.split(',')[2] }}</p>
                <p class="text-center text-2xl font-bold text-white mdmax:text-base" v-if="price.split(',')[1]">{{ price.split(',')[1] || 0 }}</p>
                <p class="text-center text-xs text-white" v-if="price.split(',')[0]">{{ price.split(',')[0] || 'Price' }}</p>
              </div>
            </div>
            <span class="block text-center text-xs mt-6">*Students will be contacted by admin to verify</span>
          </div>
        </div>
      </div>
      <div class="bg-clouducation-300 relative overflow-hidden">
        <div class="flip absolute -left-48 top-0 z-0" v-if="!isMobile">
          <Hexagon color="white" opacity="0.7" />
        </div>
        <div class="absolute -right-72 bottom-0 z-0" v-if="!isMobile">
          <Hexagon color="white" opacity="0.7" />
        </div>
        <div class="container pre-event py-16 mdmax:py-8 relative z-1">
          <p class="text-center text-5xl leading-normal font-bold mb-10 mdmax:mb-6 text-white mdmax:text-2xl">Pre-event</p>
          <div class="flex gap-14 mdmax:flex-col mdmax:gap-8">
            <div class="flex-1 mdmax:w-80 mdmax:mx-auto" v-for="(preevent, index) in multiProgram" :key="index">
              <div class="mb-4 shadow-soft rounded-lg">
                <img :src="preevent.url1" class="rounded-lg" />
              </div>
              <div class="text-center flex justify-center items-center mt-4 gap-6" v-if="isMobile">
                <p class="font-bold text-sm text-white">
                  <Button buttonText="Book Your Seat" type="tertiary" @action="openPreEvent(index)" />
                </p>
                <a
                  :href="preevent.location"
                  class="inline-flex justify-center items-center gap-2 border-l pl-6"
                  target="_blank"
                  v-if="isPreEventStarted(`${preevent.schedule}`, `${preevent.scheduleFrom}`, `${preevent.scheduleTo}`)"
                >
                  <Video color="yellow" />
                  <span class="text-white text-sm font-bold">Zoom Link</span>
                </a>
              </div>
              <div class="text-center" v-else>
                <a
                  :href="preevent.location"
                  target="_blank"
                  class="inline-flex justify-center items-center gap-2"
                  v-if="isPreEventStarted(`${preevent.schedule}`, `${preevent.scheduleFrom}`, `${preevent.scheduleTo}`)"
                >
                  <Video color="yellow" />
                  <span class="text-white text-sm font-bold">Zoom Link</span>
                </a>
              </div>
            </div>
          </div>
          <div class="text-center mt-6" v-if="!isMobile">
            <Button buttonText="Book Your Seat" type="tertiary" @action="openPreEvent()" />
          </div>
        </div>
      </div>
      <div class="bg-clouducation-black-v2">
        <div class="container why py-16 text-white mdmax:py-10 mdmax:px-4 mdmax:flex-col mdmax:gap-4 flex items-center gap-16" id="learnmore">
          <div class="flex-1 mdmax:order-2">
            <div class="mdmax:mb-4">
              <img :src="programDetail.url1" />
            </div>
          </div>
          <div class="flex-1 text-left mdmax:order-1">
            <div class="text-5xl font-bold text-left mb-8 leading-tight mdmax:text-center mdmax:text-2xl">Why <span class="text-yellow">CLOUD</span>UCATION?</div>
            <p class="text-left mdmax:text-sm mdmax:text-center mdmax:w-full" v-html="programDetail.classValue"></p>
          </div>
        </div>
      </div>
      <div class="schedule relative overflow-hidden">
        <div class="absolute -right-24 bottom-0 z-0" v-if="!isMobile">
          <Hexagon color="white" opacity="0.7" />
        </div>
        <div class="container why py-16 text-white mdmax:py-10 mdmax:px-4">
          <div class="w-3/5 mdmax:w-full">
            <div class="text-5xl font-bold text-center mb-10 mdmax:text-2xl">Main Event Timeline</div>
            <div class="flex justify-center items-center flex-col mdmax:justify-start mdmax:items-start">
              <div class="w-full flex items-center justify-center mdmax:items-start mdmax:w-1/5">
                <Clock width="56" height="56" color="#00b3be" />
              </div>
              <template v-if="!isMobile">
                <div class="flex justify-center w-full" v-for="(item, index) in programDetail.sylabusClasses" :key="index">
                  <div class="flex-1 flex w-full items-center" :class="[index % 2 === 0 ? 'order-1 text-right' : 'order-3 text-left']">
                    <div v-html="item.description" class="time w-full"></div>
                  </div>
                  <div class="flex-1 text-center flex justify-center items-center order-2 relative">
                    <span class="inline-block w-7 h-7 rounded-full bg-tosca"></span>
                    <span class="w-px bg-tosca absolute left-1/2 top-0 transform -translate-x-1/2" :class="[index === programDetail.sylabusClasses.length - 1 ? 'h-1/2' : 'h-full']"></span>
                  </div>
                  <div class="flex-1 py-4" :class="[index % 2 === 0 ? 'order-3' : 'order-1 text-right']">
                    <component :is="item.courseKit" color="tosca" />
                    <p class="text-xl font-semibold mt-2">{{ item.title }}</p>
                    <span v-if="!item.courseKit" class="inline-block h-1 bg-tosca w-32"></span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="flex justify-center w-full" v-for="(item, index) in programDetail.sylabusClasses" :key="index">
                  <div class="w-1/5 text-center flex justify-center items-center relative">
                    <span class="inline-block w-7 h-7 rounded-full bg-tosca"></span>
                    <span class="w-px bg-tosca absolute left-1/2 top-0 transform -translate-x-1/2" :class="[index === programDetail.sylabusClasses.length - 1 ? 'h-1/2' : 'h-full']"></span>
                  </div>
                  <div class="w-4/5 mb-9">
                    <div class="mb-4">
                      <component :is="item.courseKit" color="tosca" />
                      <p class="text- font-semibold mt-2">{{ item.title }}</p>
                      <span v-if="!item.courseKit" class="inline-block h-1 bg-tosca w-32"></span>
                    </div>
                    <div class="flex w-full items-center">
                      <div v-html="item.description" class="time w-full"></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="what relative overflow-hidden">
        <div class="absolute -left-72 -top-24 z-0" v-if="!isMobile">
          <div class="transform scale-75">
            <div class="flip">
              <Hexagon color="white" opacity="0.7" />
            </div>
          </div>
        </div>
        <div class="container why py-16 text-white mdmax:py-10 mdmax:px-4">
          <div class="text-5xl font-bold text-center mb-10 mdmax:text-2xl">What will you get?</div>
          <div class="flex gap-5 items-center mb-2 mdmax:flex-col">
            <div class="flex-1 p-4 justify-center flex flex-col gap-2 items-center mdmax:mb-6">
              <span class="w-24 h-24 bg-tosca rounded-full flex justify-center items-center p-2">
                <Portfolio width="96" height="96" color="white" />
              </span>
              <p class="text-xl font-semibold">Information</p>
              <div class="text-center">Access to a treasure trove of information on all things related to cloud technology</div>
            </div>
            <div class="flex-1 p-4 justify-center flex flex-col gap-2 items-center mdmax:mb-6">
              <span class="w-24 h-24 bg-tosca rounded-full flex justify-center items-center p-2">
                <Student width="50" height="96" color="white" />
              </span>
              <p class="text-xl font-semibold">Q&A</p>
              <div class="text-center">A chance to exchange knowledge with experts in the field</div>
            </div>
            <div class="flex-1 p-4 justify-center flex flex-col gap-2 items-center">
              <span class="w-24 h-24 bg-tosca rounded-full flex justify-center items-center p-2">
                <Certificate width="96" height="96" color="white" />
              </span>
              <p class="text-xl font-semibold">Certificate</p>
              <div class="text-center">A certificate of completion that proves your attendance at the event</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter @scroll="scrollTo" />
    <Sidebar :isNavOpen="isNavOpen" @close="toggleSidebar()">
      <template slot="menu">
        <div class="py-3 border-b border-white">
          <div class="text-lg text-white font-semibold block" @click="scrollTo('#hero')">Home</div>
        </div>
        <div class="py-3 border-b border-white">
          <div class="text-lg text-white font-semibold block" @click="scrollTo('#about-section')">About</div>
        </div>
        <div class="py-3 border-b border-white">
          <div class="text-lg text-white font-semibold block" @click="scrollTo('#learnmore')">Learn More</div>
        </div>
        <div class="py-3 border-b border-white" v-if="!isUserEventLoggedIn">
          <div class="text-lg text-white font-semibold block" @click="toggleLoginModal()">Login</div>
        </div>
        <div class="py-3 border-b border-white" v-else>
          <div class="text-lg text-white font-semibold block" @click="doLogout()">Logout</div>
        </div>
        <div class="py-3 mt-4" v-if="!isUserEventLoggedIn">
          <div class="text-base inline-block text-yellow rounded px-6 py-2 bg-white font-bold" @click="toggleRegistration()">Register</div>
        </div>
      </template>
    </Sidebar>
    <Modal :modalVisible="isVisibleLoginModal" @close="toggleLoginModal()" width="640px" id="login">
      <template slot="modal-content">
        <LoginForm @reset="switchToResetPassword()" @loginsuccess="toggleLoginModal()" :coreClassId="programDetail.id" />
      </template>
    </Modal>
    <Modal :modalVisible="isVisibleResetPassword" @close="toggleResetPassword()" width="640px" id="reset">
      <template slot="modal-content">
        <ResetPassword @login="toggleLoginModal()" :coreClassId="programDetail.id" @close="toggleResetPassword()" />
      </template>
    </Modal>
    <Modal :modalVisible="isVisibleRegistration" @close="toggleRegistration()" width="640px" id="registration">
      <template slot="modal-content">
        <EventRegistration @showtnc="toggleTnC()" :coreClassId="coreClassId" />
      </template>
    </Modal>
    <Modal :modalVisible="isVisiblePreEventBenefit" @close="closePreEvent()" width="1044px" type="full" corner="flat" id="pre-event">
      <template slot="modal-content">
        <PreEventBenefit :eventData="eventData" @rsvp="doRsvp()" />
      </template>
    </Modal>
    <Modal :modalVisible="isVisibleTnC" @close="toggleTnC()" width="640px" id="tnc">
      <template slot="modal-content">
        <TermAndCondition />
      </template>
    </Modal>
    <Modal :modalVisible="isVisibleRSVP" @close="toggleRSVP()" width="640px" id="rsvp">
      <template slot="modal-content"><RSVP :program="multiProgram" @success="toggleSuccessRSVP()" /> </template>
    </Modal>
    <Modal :modalVisible="isVisibleSuccessRSVP" @close="toggleSuccessRSVP()" width="640px" id="rsvp">
      <template slot="modal-content">
        <p class="text-3xl font-bold mb-8">Thank you for the RSVP!</p>
        <p class="mb-10">
          We’ll send you a reminder with the zoom link attached.<br />
          Looking forward to seeing you at our Clouducation Pre-event!
        </p>
        <Button buttonText="Okay" @action="toggleSuccessRSVP()" />
      </template>
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { scrollToTheTarget } from '@/utils'
import dayjs from 'dayjs'
export default {
  components: {
    G2Academy: () => import(/* webpackChunkName: "icon" */ '@/components/icons/G2Academy'),
    Video: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Video'),
    Hamburger: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Hamburger'),
    Time: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Time'),
    CalendarNumber: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CalendarNumber'),
    Instagram: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Instagram'),
    Portfolio: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Portfolio'),
    Hexagon: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Hexagon'),
    Student: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Student'),
    Certificate: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Certificate'),
    Clock: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Clock'),
    Coffee: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Coffee'),
    Meal: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Meal'),
    StopWatch: () => import(/* webpackChunkName: "icon" */ '@/components/icons/StopWatch'),
    Facebook: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Facebook'),
    Linkedin: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Linkedin'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Countdown: () => import(/* webpackChunkName: "countdown" */ '@/components/countdown/Countdown'),
    Sidebar: () => import(/* webpackChunkName: "sidebar" */ '@/views/Microsite/Sidebar'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    LoginForm: () => import(/* webpackChunkName: "login-form" */ '@/views/Microsite/LoginForm'),
    ResetPassword: () => import(/* webpackChunkName: "reset-password" */ '@/views/Microsite/ResetPassword'),
    EventRegistration: () => import(/* webpackChunkName: "event-registration" */ '@/views/Microsite/EventRegistration'),
    PreEventBenefit: () => import(/* webpackChunkName: "pre-event-benefit" */ '@/views/Microsite/PreEventBenefit'),
    TermAndCondition: () => import(/* webpackChunkName: "term-condition" */ '@/views/Microsite/TermAndCondition'),
    RSVP: () => import(/* webpackChunkName: "rsvp" */ '@/views/Microsite/RSVP'),
    TheHeader: () => import(/* webpackChunkName: "header" */ '@/views/Microsite/TheHeader'),
    TheFooter: () => import(/* webpackChunkName: "footer" */ '@/views/Microsite/TheFooter')
  },
  data: () => ({
    isNavOpen: false,
    isVisibleLoginModal: false,
    isVisibleResetPassword: false,
    isVisibleRegistration: false,
    isVisiblePreEventBenefit: false,
    isVisibleTnC: false,
    isVisibleRSVP: false,
    isVisibleSuccessRSVP: false,
    preEventList: ['preevent-1', 'preevent-2', 'preevent-3'],
    multiProgram: [],
    eventData: []
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('program', ['programDetail']),
    ...mapGetters('auth', ['isUserEventLoggedIn']),
    coreClassId() {
      return this.programDetail.id
    },
    priceGroup() {
      return this.programDetail?.benefitText && this.programDetail?.benefitText.split('|')
    }
  },
  created() {
    this.getEventDetail()
    this.getPreevent()
  },
  methods: {
    ...mapActions('program', ['getProgramBySlug', 'getMultipleProgramBySlug', 'enrollV2']),
    ...mapActions('auth', ['removeUserEvent']),
    goToUrl(url) {
      window.open(url)
    },
    formatDisplayDate(date, time) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD')
      return `${formattedDate}T${time && time.replace('.', ':')}:00`
    },
    isEventStarted(date, start, end) {
      const formattedStartDate = this.formatDisplayDate(date, start)
      const formattedEndDate = this.formatDisplayDate(date, end)
      const today = new Date().getTime()
      const eventStartDate = new Date(formattedStartDate).getTime()
      const eventEndDate = new Date(formattedEndDate).getTime()
      return today > eventStartDate && today < eventEndDate
    },
    isEventEnded(date, end) {
      const formattedDate = this.formatDisplayDate(date, end)
      const eventEndDate = new Date(formattedDate).getTime()
      const today = new Date().getTime()
      return today > eventEndDate
    },
    isPreEventStarted(date, start, end) {
      const formattedStartDate = this.formatDisplayDate(date, start)
      const formattedEndDate = this.formatDisplayDate(date, end)
      const today = new Date()
      const eventEndDate = new Date(formattedEndDate)
      const eventStartDate = new Date(formattedStartDate)
      const substractedDate = eventStartDate.setHours(eventStartDate.getHours() - 3)
      return today.getTime() > substractedDate && today.getTime() < eventEndDate.getTime()
    },
    async processPreevent() {
      let result
      const constructedArray = []
      for (let i = 0; i < this.preEventList.length; i++) {
        result = await this.getMultipleProgramBySlug({
          slug: this.preEventList[i]
        })
        constructedArray.push(result)
      }
      return constructedArray
    },
    async getPreevent() {
      this.multiProgram = await this.processPreevent()
    },
    scrollTo(target) {
      if (this.isMobile) {
        this.toggleSidebar()
      }
      scrollToTheTarget(target, -100)
    },
    toggleSidebar() {
      this.isNavOpen = !this.isNavOpen
    },
    toggleLoginModal() {
      this.isVisibleLoginModal = !this.isVisibleLoginModal
    },
    switchToResetPassword() {
      this.isVisibleLoginModal = false
      this.toggleResetPassword()
    },
    toggleTnC() {
      this.isVisibleTnC = !this.isVisibleTnC
    },
    toggleResetPassword() {
      this.isVisibleResetPassword = !this.isVisibleResetPassword
    },
    toggleRegistration() {
      this.isVisibleRegistration = !this.isVisibleRegistration
    },
    toggleRSVP() {
      this.isVisibleRSVP = !this.isVisibleRSVP
    },
    doRsvp() {
      this.isVisiblePreEventBenefit = false
      this.toggleRSVP()
    },
    toggleSuccessRSVP() {
      this.isVisibleRSVP = false
      this.isVisibleSuccessRSVP = !this.isVisibleSuccessRSVP
    },
    openPreEvent(index) {
      this.eventData = []
      if (typeof index === 'undefined') {
        this.eventData = this.multiProgram
      } else {
        this.eventData.push(this.multiProgram[index])
      }
      this.isVisiblePreEventBenefit = true
    },
    closePreEvent() {
      this.isVisiblePreEventBenefit = false
    },
    getEventDetail() {
      this.getProgramBySlug({
        slug: 'clouducation'
      })
    },
    doLogout() {
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserEvent())
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@use 'sass:math';
.clouducation {
  margin-top: -110px;
  padding-top: 78px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
    padding-top: 0;
  }
}
.body {
  position: relative;
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
.image-container {
  background-color: #d6d6d6;
  width: 100%;
  padding-top: 50%; /* 2:1 Aspect Ratio */
  position: relative;
}
.what-item {
  &::v-deep(ul) {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    li {
      flex: 1 1 0%;
    }
  }
}
.time {
  &::v-deep(ul) {
    padding: 0;
    margin: 0;
    li {
      &:first-child {
        font-size: 36px;
        font-weight: bold;
        color: #00b3be;
        margin-bottom: 4px;
        strong {
          font-size: 18px;
        }
      }
      &:last-child {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.cloud-title {
  font-size: 84px;
  line-height: normal;
  @media screen and (max-width: 767px) {
    font-size: 36px;
  }
}
.hero {
  background-image: url(~@/assets/images/microsite/hero-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 767px) {
    background-position: center;
  }
}
.schedule {
  background-image: url(~@/assets/images/microsite/bg-schedule.webp);
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 767px) {
    background-position: center;
  }
}
.what {
  background-image: url(~@/assets/images/microsite/what-will-you-get.png);
  background-repeat: no-repeat;
  background-size: cover;
}

$color: #822e87;
$width: 178px;
$widthMobile: 128px;
$height: math.div($width, 1.6);
$heightMobile: math.div($widthMobile, 1.6);

.hex {
  width: $width;
  height: $height;
  background-color: $color;
  border-color: $color;
  position: relative;
  margin: math.div($height, 2) 0;
  color: $color;
  @media screen and (max-width: 767px) {
    width: $widthMobile;
    height: $heightMobile;
    margin: math.div($heightMobile, 2) 0;
  }
}
.hex:before {
  content: ' ';
  width: 0;
  height: 0;
  border-bottom: math.div($height, 2) solid;
  border-color: $color;
  border-left: math.div($width, 2) solid transparent;
  border-right: math.div($width, 2) solid transparent;
  position: absolute;
  top: math.div($height, 2) * -1;
  @media screen and (max-width: 767px) {
    border-bottom: math.div($heightMobile, 2) solid;
    border-left: math.div($widthMobile, 2) solid transparent;
    border-right: math.div($widthMobile, 2) solid transparent;
    top: math.div($heightMobile, 2) * -1;
  }
}
.hex:after {
  content: '';
  width: 0;
  position: absolute;
  bottom: math.div($height, 2) * -1;
  border-top: math.div($height, 2) solid;
  border-color: $color;
  border-left: math.div($width, 2) solid transparent;
  border-right: math.div($width, 2) solid transparent;
  @media screen and (max-width: 767px) {
    bottom: math.div($heightMobile, 2) * -1;
    border-top: math.div($heightMobile, 2) solid;
    border-left: math.div($widthMobile, 2) solid transparent;
    border-right: math.div($widthMobile, 2) solid transparent;
  }
}
.flip {
  transform: scale(-1);
  -webkit-transform: scale(-1);
}
</style>
